<template>
  <div>
    <Breadcrumbs :items="breadcrumbs" page_title="email" />
    <v-container fluid>
      <!-- New UI -->
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12">
          <v-card>
            <v-card-title class="noti-icon" height="70px">
              <v-icon style="font-size:30px;">mdi-file-document</v-icon><p class="title_style">{{ $t("Email Welcome") }}</p>
              <v-spacer></v-spacer>
                <div class="onoffswitch mt-3 ml-5" >
                        <input
                          type="checkbox"
                          name="onoffswitch"
                          class="onoffswitch-checkbox"
                          id="myonoffswitch1"
                          v-model="welcome.welcomeActive"
                        />
                        <label class="onoffswitch-label" for="myonoffswitch1">
                          <div class="onoffswitch-inner"></div>
                          <div class="onoffswitch-switch"></div>
                        </label>
                      </div>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-row class="d-flex">
                <v-col>
                  <div class="subtitle">
                        {{ $t("TH") }}
                      </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                  v-model="welcome.welcomeTH"
                  dense
                  class="shrink"
                  outlined
                  hide-details="auto"
                  maxlength="300"
                  counter
                >
                </v-textarea>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide" >
                <v-col cols="12">
                  <img alt src="@/assets/TH.jpg" height="500"/>
                </v-col>
              </v-row>
              <v-row class="d-flex">
                <v-col>
                  <div class="subtitle">
                        {{ $t("EN") }}
                      </div>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    v-model="welcome.welcomeEN"
                    dense
                    class="shrink"
                    outlined
                    hide-details="auto"
                    maxlength="300"
                    counter
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row class="mt-0 divide" >
                <v-col cols="12">
                  <img alt src="@/assets/EN.jpg" height="500" />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    
      <v-row class="ma-8 d-flex justify-end">
        <v-btn
          width="110"
          @click="CreateOrUpdateEmailTemplate()"
          class="text-capitalize btnStyle"
          color="secondary"
          ><v-icon class="mr-2">mdi-content-save-outline</v-icon>{{ $t("save") }}</v-btn
        >
      </v-row>
      
    </v-container>
    
    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />

    <v-dialog v-model="loadingShow" persistent width="300">
      <v-card color="#1976D2" dark>
        <v-card-text class="white--text">
          Loading Please Wait...
          <v-progress-linear indeterminate color="#FAA91B" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <PermissionDialog
      :show="permissionDialog"
      title="Error"
      :text="permissionMessage"
      @close="permissionDialog = false"
    />
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    loadingShow: false,
    permissionDialog: false,
    permissionMessage: "",
    lock: false,
    breadcrumbs: [
      {
         sidebar_tilte: "home"
      },
      {
        text: "email"
      }
    ],
    disable: true,
    welcome: {
      welcomeTH: null,
      welcomeEN: null,
      welcomeActive: false
    },
  }),
 mounted() {
  this.GetEmailTemplate();
  },
 methods: {
  async GetEmailTemplate() {
      let that = this;
      await axios
        .get(
          `${that.web_url}SendNotification/GetEmailTemplate?CompanyID=` +
            localStorage.getItem("companyID")
        )
        .then(function(response) {
          if (response.data.status == 0) {
            if(response.data.data!=null){
              
            that.welcome = response.data.data;
            }
          }

        })
        .catch(function(err) {
          throw err;
        });
  },
  async CreateOrUpdateEmailTemplate() {
      let that = this;
      that.loadingShow = true;
      let request = {
        CompanyID: localStorage.getItem("companyID"),
        WelcomeTH: that.welcome.welcomeTH,
        WelcomeEN: that.welcome.welcomeEN,
        WelcomeActive: that.welcome.welcomeActive
      };
      await axios
        .post(`${that.web_url}SendNotification/CreateOrUpdateEmailTemplate`, request)
        .then(function(response) {
          if (response.data.status == 0) {
            that.loadingShow = false;
            alert("Save Success.");
          }
        })
        .catch(function(err) {
          throw err;
        });
      that.loadingShow = false;
    },
  },
};
</script>

<style scoped>


.title_style {
  color: #000000;
  font-size: 22px;
}
.subtitle {
  color: #47484b;
  font-size: 20px;
}
.text-style {
  color: #47484b;
  font-size: 18px;
}

::v-deep .chk-input .v-input__control {
  align-content: end;
}
::v-deep .chk-input .v-input__slot {
  width: 200px;
}
::v-deep .input-field .v-input__slot {
  width: 127px !important;
  margin-top: 12px;
  left: 60%!important;
}
.font-style {
  font-size: 16px;
  color: #000000;
}

::v-deep .btnStyle .v-btn__content {
  font-size: 20px;
  color: #fff;
}
.chk-class {
  margin-left: 30px;
}
.div-class {
  margin-left: 8rem;
  margin-top: 10px;
}
::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state)
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #707070;
  background: white;
}
::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused).v-input--is-disabled
  > .v-input__control
  > .v-input__slot
  fieldset {
  color: #bbbbbb33;
  background: #bbbbbb33;
}
</style>

<style>
.v-card{
  border-radius: 18px!important;
  padding-bottom: 20px;
}
.noti-icon .v-icon{
  color: #424242!important;
}
.v-application p {
    margin-bottom: 0px!important;
}
.divide{
  border-bottom: 1px solid #C0C0C0;
}
.v-input--switch--inset .v-input--selection-controls__input, .v-input--switch--inset .v-input--switch__track {
    width: 70px!important;
    height: 31px!important;
}
.onoffswitch {
  position: relative;
  width: 73px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 20px;
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 28px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 11px;
  background-color: #6fdc42;
  color: #ffffff;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 11px;
  color: #ffffff;
  background-color: #707070;
  opacity: 1;
  text-align: right;
}

.onoffswitch-switch {
  width: 26px;
  height: 26px;
  margin: 1px;
  background: #ffffff;
  border-radius: 20px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 44px;
  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}
</style>